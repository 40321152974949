import { Injectable } from '@angular/core';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx'
import { SharedService } from './shared.service'
import { QueueManagerService } from './queue-manager.service'
import { LOG_CATEGORIES, ROUTES, NAVIGATE_DEFAULT, RE_URL_ORGID, IONIC_STORAGE_KEYS, LEAVE_PAGES } from './constants'
import { LocalNotifications } from '@ionic-native/local-notifications/ngx'
import { database } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
    private fcm: FCM,
    private shared: SharedService,
    private queueManager: QueueManagerService,
    private localNotifications: LocalNotifications,
  ) { }

  private _hasPushNotificationPermission = false

  get hasPushNotificationPermission() {
    return this._hasPushNotificationPermission
  }

  async init() {
    this.initOnTokenRefresh()
    this.initOnNotification()
    this._hasPushNotificationPermission = await this.requestPushPermission();
    if (this._hasPushNotificationPermission) {
      this.shared.log(LOG_CATEGORIES.FCM_SERVICE, `init: push permission granted`)
      this.fireQueuedEvents()
    } else {
      this.shared.log(LOG_CATEGORIES.FCM_SERVICE, `init: push permission not granted`)
    }
  }

  initOnTokenRefresh() {
    this.fcm.onTokenRefresh().subscribe(async (newToken: string) => {
      this.shared.log(LOG_CATEGORIES.FCM_SERVICE, `OnTokenRefresh: begin with ${newToken}`)
      let state

      if (state = this.queueManager.getState() == undefined) {
        state = this.queueManager.init()
        this.shared.log(LOG_CATEGORIES.FCM_SERVICE, `OnTokenRefresh: Initialized Queue Manager`)
      }

      const diagnosis = this.shared.stateDiagnostic(state);

      if (diagnosis.hasToken) {
        const oldToken = state.token
        if (oldToken !== newToken) {
          this.shared.log(LOG_CATEGORIES.FCM_SERVICE, `OnTokenRefresh: New token received`)
          await this.shared.updateStorageAndState(this.queueManager, {
            [IONIC_STORAGE_KEYS.TOKEN] : newToken
          })
          this.shared.log(LOG_CATEGORIES.FCM_SERVICE, `OnTokenRefresh: Token updated in app state from ${oldToken.slice(0, 10)}... to ${newToken.slice(0, 10)}...}`)
          if (diagnosis.isInQueue) {
            this.shared.log(LOG_CATEGORIES.FCM_SERVICE, `OnTokenRefresh: Already in Queue, updating token in Queue DB .. `)
            await this.queueManager.updateToken(oldToken, newToken);
            await this.queueManager.subscribeToQueue(LEAVE_PAGES.LEAVE)
            this.shared.log(LOG_CATEGORIES.FCM_SERVICE,  `OnTokenRefresh: subscribed to queue`)
          }
        }
      }
      this.shared.log(LOG_CATEGORIES.FCM_SERVICE,  `OnTokenRefresh: complete`)
    })
    this.shared.log(LOG_CATEGORIES.FCM_SERVICE,  `initOnTokenRefresh`)
  }

  initOnNotification() {
    this.fcm.onNotification().subscribe((payload) => {
      this.shared.log(LOG_CATEGORIES.FCM_SERVICE,  `onNotification: Received: ${JSON.stringify(payload)}`)
      if (!payload.wasTapped) {
        //Notification was received in foreground. Maybe the user needs to be notified.
        this.shared.log(LOG_CATEGORIES.FCM_SERVICE, 'wasTapped=false')
        // temp workaround for iOS bug, which causes app to lock phone in specific scenarios
        /* this.localNotifications.schedule({
          id: 1,
          title: payload.title,
          text: payload.body,
          data: payload,
          foreground: true
        }) */
      } else {
        //Notification was received on device tray and tapped by the user.
        this.shared.log(LOG_CATEGORIES.FCM_SERVICE, 'wasTapped=true')
      }
    })
    this.shared.log(LOG_CATEGORIES.FCM_SERVICE,  `initOnNotification`)
  }

  async requestPushPermission() {
    return await this.fcm.requestPushPermission()
  }

  fireQueuedEvents() {
    this.localNotifications.fireQueuedEvents()
  }

  async getToken() {
    return await this.fcm.getToken()
  }
}
