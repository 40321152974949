import FirebaseApp from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
 //import  
// const admin = require('firebase-admin');

/* import admin from 'firebase-admin' */

export const firestoreConfig = {
  apiKey: "AIzaSyA8NS1PwnYOUyjQakwIOzIfnLArsJSG5lA",
  authDomain: "whoonext-366da.firebaseapp.com",
  databaseURL: "https://whoonext-366da.firebaseio.com",
  projectId: "whoonext-366da",
  storageBucket: "whoonext-366da.appspot.com",
  messagingSenderId: "23779768457",
  appId: "1:23779768457:web:1d36651c5a7d7efce36e58",
  measurementId: "G-FEVEJB27EZ"
};

export const app = FirebaseApp.initializeApp(firestoreConfig)
// debugger
export const db = app.firestore()
export const storageDb = app.storage()

