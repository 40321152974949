import { Injectable, ErrorHandler } from '@angular/core'
import { SharedService } from './shared.service'
import { LOG_CATEGORIES, CONNECTION_STATES, ROUTES } from './constants'
import { ConnectivityService } from './connectivity.service'
/* import { FirebaseCrashlytics } from '@ionic-native/firebase-crashlytics/ngx' */
import { Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler{
  constructor(
    private shared: SharedService,
    // private crashlytics: FirebaseCrashlytics,
    private platform: Platform,
    private connectivity: ConnectivityService,
  ) {
    // this.crashlytics.initialise()
  }

  handleError(error) {
    this.shared.log(LOG_CATEGORIES.APP, `ERROR RECEIVED: ${error.message}`)

    /* if (!this.platform.is('desktop')) {
      this.crashlytics.logException(error.message)
    } */

    if (error.message.includes('FIRESTORE ERROR')) {
      this.shared.log(LOG_CATEGORIES.APP, `${error.message}`)
      // this.shared.navigateToServerUnavailable()
      this.shared.navigateToConnectivity()
    } else if (this.connectivity.connectionStatus == CONNECTION_STATES.DISCONNECTED) {
      this.shared.navigateToConnectivity()
    } else {
      this.shared.navigateToError(error.message)
    }
  }
}

export class FirestoreError extends Error {
  constructor(message) {
    super(message)
    this.name = "FirestoreError"
  }
}