export class QueueModel {

    private queue: any

    constructor(queue) {
      if (queue === undefined) {
          this.queue = new Array()
      } else {
          this.queue = [ ...queue ]
      }
    }

    enqueue(newItem) {
        this.removeByToken(newItem.token)
        this.insert(this.queue.length, newItem)
    }

    dequeue() {
        return this.queue.shift()
    }

    insert(position, item) {
      return this.queue.splice(position, 0, item)
    }

    remove(position) {
      this.queue.splice(position, 1)
    }

    removeByToken(token) {
        const arrayPos = this.queue.findIndex(existingItem => {
            return existingItem.hasOwnProperty('token') && token === existingItem.token
        })

        // If already registered in queue,
        // remove existing order first.
        if (arrayPos !== -1) {
            this.remove(arrayPos)
            return true
        } else {
            return false
        }
    }

    replaceToken(oldToken, newToken) {
        const arrayPos = this.queue.findIndex(existingItem => {
            return existingItem.hasOwnProperty('token') && oldToken === existingItem.token
        })

        if (arrayPos !== -1) {
            this.queue[arrayPos].token = newToken
            return true
        } else {
            return false
        }
    }

    peek(index = 0) {
        return this.queue[index]
    }

    peekByToken(token) {
        const arrayPos = this.queue.findIndex(existingItem => {
            return existingItem.hasOwnProperty('token') && token === existingItem.token
        })

        if (arrayPos !== -1) {
          return this.queue[arrayPos]
        } else {
          return undefined
        }
    }

    isEmpty() {
        return this.queue.length === 0
    }

    size() {
        return this.queue.size
    }

    asArray() {
        return this.queue
    }

    tokens() {
        return this.queue.map(item => item.token);
    }
  }
