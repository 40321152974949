import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage'
import { Router } from '@angular/router'
import { Platform } from '@ionic/angular'
import { QueueManagerService } from './queue-manager.service'
import { LOG_CATEGORIES, LOG_APP_NAME, IONIC_STORAGE_KEYS, ROUTES, NAVIGATE_DEFAULT, LEAVE_PAGES, RE_URL_ORGID } from './constants'
import { db } from './db'
import { Device } from '@ionic-native/device/ngx'


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private platform: Platform,
    private ionicStorage: Storage,
    private router: Router,
    private device: Device
  ) {}

  isInQueue(state) {
    const diagnosis = this.stateDiagnostic(state);
    return (diagnosis.hasOrgId && diagnosis.hasToken && diagnosis.hasQueueId && diagnosis.isInQueue)
  }

  stateDiagnostic(state) {
    const diagnosis =  {
      hasOrgId: state.hasOwnProperty(IONIC_STORAGE_KEYS.ORG_ID) && state[IONIC_STORAGE_KEYS.ORG_ID] != undefined,
      hasQueueId: state.hasOwnProperty(IONIC_STORAGE_KEYS.QUEUE_ID) && state[IONIC_STORAGE_KEYS.QUEUE_ID] != undefined,
      hasToken: state.hasOwnProperty(IONIC_STORAGE_KEYS.TOKEN)  && state[IONIC_STORAGE_KEYS.TOKEN] != undefined,
      isInQueue: state.hasOwnProperty(IONIC_STORAGE_KEYS.IN_QUEUE) && state[IONIC_STORAGE_KEYS.IN_QUEUE] == true
    }
    this.log(LOG_CATEGORIES.SHARED, `Diagnosis: ${JSON.stringify(diagnosis)}`)
    return diagnosis
  }

  addToStorage(key: string, value: any) {
    return this.addObjectToStorage({ [key] : value })
  }

  addObjectToStorage(addObject: any) {
      return this.ionicStorage.get('state').then(state => {
          this.ionicStorage.set('state', { ...state, ...addObject })
      })
  }

  async updateStorageAndState(queueManager: QueueManagerService, addObject: any) {
    await this.addObjectToStorage(addObject)
    .then(() => {
      queueManager.addObjectToState(addObject)
    })
  }

  removeKeysFromStorage(keys: string[]) {
    let promises = [];
    keys.forEach(key => promises.push(this.ionicStorage.remove(key)));
    return Promise.all(promises);
  }

  setStorage(obj: any) {
    return this.ionicStorage.set('state', obj )
  }

  log(category: string, message: string) {
      console.log(`${LOG_APP_NAME}: ${category}: ${message}`)
  }

  navigateToError(message) {

    const state = {
      [ IONIC_STORAGE_KEYS.ERROR ]: message,
    }
    return this.addObjectToStorage(state).then(() => {
      this.router.navigate([ROUTES.ERROR], NAVIGATE_DEFAULT)
    })
  }

  navigateToServerUnavailable() {
    this.router.navigate([ROUTES.SERVER_UNAVAILABLE])
  }

  navigateToConnectivity() {
    this.router.navigate([ROUTES.CONNECTIVITY])
  }

  async scanOrg(queueManager) {
    if (this.isBrowser() || this.device.isVirtual) {
      const querySnapshotOrg = await db.collection('org').where('test', '==', true).limit(1).get()

      if (querySnapshotOrg.empty) {
        this.log(LOG_CATEGORIES.SHARED, 'Desktop version: No test orgs found. Add a key-value pair "test=true" to an org document to test an org.')
        return
      }

      const queryDocumentSnapshotOrg = querySnapshotOrg.docs[0]
      const orgId = queryDocumentSnapshotOrg.id

      await this.updateStorageAndState(queueManager, {
        [ IONIC_STORAGE_KEYS.ORG_ID ]: orgId
      })
      
      this.router.navigate([ ROUTES.QUEUE ], NAVIGATE_DEFAULT)
    } else {
        this.router.navigate([ ROUTES.SCAN ], NAVIGATE_DEFAULT)
    }
  }

  get isMobileApp() {
    return (this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb') && !(this.device.platform == 'browser')
  }

  get isMobileAppOrVirtualDevice() {
    return this.isMobileApp || this.device.isVirtual
  }

  isBrowser() {
    return (this.platform.is('mobileweb') || this.platform.is('desktop')) || this.device.platform == 'browser'
  }

  // https://stackoverflow.com/questions/42782645/how-to-create-guid-in-angular-2/42782731
  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  async initializeApp(queueManager) {
    let state = await queueManager.init()
    if (this.isInQueue(state)) {
      await queueManager.subscribeToQueue(LEAVE_PAGES.LEAVE)
      this.log(LOG_CATEGORIES.SHARED, `Subscribed to existing queue`)
    } else {
      if (this.isMobileAppOrVirtualDevice) {
        this.router.navigate([ROUTES.HOME], NAVIGATE_DEFAULT)
      } else {
        const url = this.platform.url()
        this.log(LOG_CATEGORIES.SHARED,  `Platform URL ${url}`)
        const match = url.match(RE_URL_ORGID)

        if (match === null) {
          this.log(LOG_CATEGORIES.SHARED,  `Not a valid URL`)
          this.router.navigate([ROUTES.HOME], NAVIGATE_DEFAULT)
        } else {
          const orgId = match[1]
          await queueManager.showQueuePage(orgId)
        }
      }
    }
  }
}
