export const DEFAULT_ORG_ID = 'KE8NEkYg3S56sSDSr1ro'
export const LEAVE_PAGES = {
    HOME: 'home',
    LEAVE: 'leave'
}
export const IONIC_STORAGE_KEYS = {
    DEVICE_ID: 'deviceId',
    DEVICE_AVAILABLE: 'deviceAvailable',
    ORG_ID: 'orgId',
    QUEUE_ID: 'queueId',
    IN_QUEUE: 'inQueue',
    TOKEN: 'token',
    TOKEN_AVAILABLE: 'tokenAvailable',
    ERROR: 'error',
    PRIVATE_QUEUE_MESSAGE: 'privateQueueMessage'
}
export const ROUTES = {
    HOME: '/home',
    QUEUE: '/queue',
    SCAN: '/scan',
    LEAVE: '/leave',
    POSITION_ONE: '/position-one',
    POSITION_TWO: '/position-two',
    POSITION_THREE: '/position-three',
    STATUS: '/status',
    UNAVAILABLE: '/unavailable',
    ERROR: '/error',
    CONNECTIVITY: '/connectivity',
    SERVER_UNAVAILABLE: '/server-unavailable',
    PRIVATE_QUEUE: '/private-queue'
}

export const CONNECTION_STATES = {
    CONNECTED: 'connected',
    DISCONNECTED: 'disconnected'
}

export const LOG_APP_NAME = 'WHOONEXT'
export const LOG_CATEGORIES = {
    HOME: 'HomePage',
    QUEUE: 'QueuePage',
    SCAN: 'ScanPage',
    APP: 'AppComponent',
    POSITION_ONE: 'PositionOnePage',
    POSITION_TWO: 'PositionTwoPage',
    POSITION_THREE: 'PositionThreePage',
    QUEUE_MANAGER: 'QueueManager',
    STATUS: 'StatusPage',
    LEAVE: 'LeavePage',
    GUARD: 'Guard',
    ERROR: 'ErrorPage',
    UNAVAILABLE: 'UnavailablePage',
    FCM_SERVICE: 'FCMService',
    SHARED: 'SharedService',
    CONNECTIVITY: 'ConnectivityService',
    CONNECTIVITY_PAGE: 'ConnectivityPage',
    SERVER_UNAVAILABLE: 'ServerUnavailable',
    PRIVATE_QUEUE: 'PrivateQueuePage'
}

export const NAVIGATE_DEFAULT = {
    skipLocationChange: true,
    animated: false
}

export const RE_WHOONEXT_URL = /^https\:\/\/app\.whoonext\.com\/\?org=(.+)$/
// export const RE_WHOONEXT_URL = /^http\:\/\/192\.168\.178\.93\:8000\/\?org=(.+)$/

export const RE_URL_ORGID = /^.*org=(.+)$/

