import { Component } from '@angular/core'

import { Platform } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { QueueManagerService } from '../queue-manager.service'
import { Storage } from '@ionic/storage'
import { Router } from '@angular/router'
import { SharedService } from '../shared.service'
import { ConnectivityService } from '../connectivity.service'
import { LOG_CATEGORIES, CONNECTION_STATES, IONIC_STORAGE_KEYS } from '../constants'
import { FcmService } from '../fcm.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private queueManager: QueueManagerService,
    private ionicStorage: Storage,
    private shared: SharedService,
    private connectivity: ConnectivityService,
    private fcmService: FcmService,
    private router: Router,
  ) {
    this.shared.log(LOG_CATEGORIES.APP, `Beginning`)
    this.bootstrapApp().then((bootstrapAppResult) => {
      if (bootstrapAppResult) {
        if (this.connectivity.connectionStatus == CONNECTION_STATES.CONNECTED) {
          this.shared.initializeApp(this.queueManager).then(() => {
            this.shared.log(LOG_CATEGORIES.APP, `App initialized`)
          })
        }
      } else {
        this.shared.log(LOG_CATEGORIES.APP, `Could not initialize app`)
      }
    })
  }

  async bootstrapApp() {
    try {
      await this.platform.ready()
      this.shared.log(LOG_CATEGORIES.APP, `Platform Ready`)

      this.platform.backButton.subscribeWithPriority(9999, () => {
        this.shared.log(LOG_CATEGORIES.APP, `Back Button Disabled`)
      })

      this.platform.resume.subscribe((result)=>{
        console.log('Platform Resume Event');
        this.shared.initializeApp(this.queueManager);
      });

      this.platform.pause.subscribe((result) => {
        this.queueManager.unsubscribe()
      })

      await this.ionicStorage.ready()
      this.shared.log(LOG_CATEGORIES.APP, `Initialized Ionic Storage`)
      this.shared.log(LOG_CATEGORIES.APP, `Ionic Storage Driver ${this.ionicStorage.driver}`)

      this.connectivity.init();

      this.statusBar.styleDefault()
      this.splashScreen.hide()

      let state = await this.queueManager.init()
      
      if (this.shared.isMobileAppOrVirtualDevice) {
        await this.fcmService.init();
      }

      if (!this.shared.isInQueue(state)) {
        let token = this.shared.newGuid()
        if (this.shared.isMobileAppOrVirtualDevice) {
          if (this.fcmService.hasPushNotificationPermission) {
            token = await this.fcmService.getToken()
          }
        }
        this.shared.log(LOG_CATEGORIES.APP, `App Token ${token}`)
  
        await this.shared.updateStorageAndState(this.queueManager, {
          [ IONIC_STORAGE_KEYS.TOKEN ]: token,
        })
        
        this.shared.log(LOG_CATEGORIES.APP,  `Storage initialized with token`)
      }
      
      this.shared.log(LOG_CATEGORIES.APP, `bootstrapApp complete`)
      return true
    } catch(e) {
      this.shared.log(LOG_CATEGORIES.APP, `bootstrapApp failed with exception`)
      this.shared.log(LOG_CATEGORIES.APP, e.message)
      return false
    }
  }
}
