import { Storage } from '@ionic/storage'
import { Subscription, Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { QueueModel } from './model'
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx'
import { LEAVE_PAGES, ROUTES, IONIC_STORAGE_KEYS, LOG_CATEGORIES, NAVIGATE_DEFAULT } from './constants'
import { SharedService } from './shared.service'
import { LocalNotifications } from '@ionic-native/local-notifications/ngx'
import { Platform } from '@ionic/angular'
import { db } from './db'
import { firestore } from 'firebase/app'
import { Device } from '@ionic-native/device/ngx'
import { FirestoreError } from './error.service'

@Injectable({
  providedIn: 'root'
})
export class QueueManagerService {

  private state: any
  private subscription: Subscription
  private refOrg: firestore.DocumentReference
  private refQueue: firestore.DocumentReference
  private $queue: Observable<any>

  constructor(
    private platform: Platform,
    private router: Router,
    private ionicStorage: Storage,
    private fcm: FCM,
    private localNotifications: LocalNotifications,
    private shared: SharedService,
    private device: Device
    ) {}

  retrieveState() {
    return this.state
  }

  setState(state) {
    this.state = state
  }

  async init() {
    this.state = await this.ionicStorage.get('state') || {}
    this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `init: state: ${JSON.stringify(this.state)}`)
    this.$queue = undefined
    this.refOrg = undefined
    return this.state
  }

  async updateToken(oldToken, newToken) {
    const documentReferenceQueue = await this.queueDoc()
    if (documentReferenceQueue != undefined) {
      let doc = await documentReferenceQueue.get()
      const data = doc.data()
      const queueModel = new QueueModel(data.queue)
      const queue = queueModel.asArray()
      queueModel.replaceToken(oldToken, newToken)
      await documentReferenceQueue.update({  queue, tokens: queueModel.tokens() })
    }
  }

  orgDoc() {
    if ((!this.refOrg) || (this.refOrg.id !== this.state.orgId)) {
      this.refOrg = db.collection('org').doc(this.state.orgId)
    }
    return this.refOrg
  }

  async queryOrgDoc() {
    const documentReferenceOrg = this.orgDoc()
    const documentSnapshotOrg = await documentReferenceOrg.get()
    if (!documentSnapshotOrg.exists) {
      const message = `FIRESTORE ERROR: Could not query org ${this.orgDoc().id}.`
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, message)
      throw new FirestoreError(message)
    }
    return {
      data: documentSnapshotOrg.data(),
      id: documentSnapshotOrg.id
    }
  }

  async queryCollectionFirstRecord(collectionName) {
    const querySnapshotQueue = await this.orgDoc().collection(collectionName).limit(1).get()
    if (querySnapshotQueue.empty) {
      const message = `FIRESTORE ERROR: No '${collectionName}' collection found for org ${this.orgDoc().id}.`
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, message)
      throw new FirestoreError(message)
    }
    return querySnapshotQueue.docs[0]
  }

  async queryCollectionForToken(collectionName, token) {
    const querySnapshotQueue =
      await this.orgDoc().collection(collectionName)
      .where('tokens', 'array-contains', token)
      .limit(1).get();
      
    return querySnapshotQueue.empty ? undefined : querySnapshotQueue.docs[0]
  }

  async queryCollectionForQueueName(collectionName, queueName) {
    const querySnapshotQueue =
      await this.orgDoc().collection(collectionName)
      .where('name', '==', queueName)
      .limit(1).get();
      
    return querySnapshotQueue.empty ? undefined : querySnapshotQueue.docs[0]
  }

  async queueDoc(queueName = undefined) {
    let refQueue
    let queryDocumentSnapshot

    // if ((!this.refQueue) || (this.refQueue.id !== this.state.queueId)) {
    if (queueName == undefined) {
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Token ${this.state.token}`)
      queryDocumentSnapshot = await this.queryCollectionForToken('queues', this.state.token)
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Query result ${queryDocumentSnapshot}`)
    } else {
      queryDocumentSnapshot = await this.queryCollectionForQueueName('queues', queueName)
    }

    if (queryDocumentSnapshot !== undefined) {
      refQueue = queryDocumentSnapshot.ref
    } else {
      refQueue = undefined
    }
    // }
    return refQueue
  }

  async isApproved(orgId) {
    const querySnapshotQueue = await db.collection('org').doc(orgId).collection('approval').limit(1).get()
    if (querySnapshotQueue.empty) {
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, (`No 'approval' collection found for org ${orgId}.`))
      return false
    }
    const dataApproval = querySnapshotQueue.docs[0].data()
    const isApproved = dataApproval.approved
    this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, (`Approval:  ${isApproved}.`))
    return (dataApproval.hasOwnProperty('approved')) ? dataApproval.approved : false
  }

  async isFrozen(orgId) {
    const documentSnapshotOrg = await db.collection('org').doc(orgId).get()
    const dataOrg = documentSnapshotOrg.data()

    const isFrozen = dataOrg.hasOwnProperty('frozen') && dataOrg.frozen
    this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, (`Frozen:  ${isFrozen}.`))
    return isFrozen
  }

  observeDoc(ref: firestore.DocumentReference) {
    return new Observable(subscriber => {
      const unsubscribe = ref.onSnapshot(subscriber)
      return () => {
        if (unsubscribe != null) {
          unsubscribe()
        }
      }
    })
  }

  async liveData(queueName = undefined) {
    // if ((this.subscription !== undefined && this.subscription.closed)/* || !this.$queue*/) {
    const refQueue = queueName == undefined ? await this.queueDoc() : await this.queueDoc(queueName)
    if (refQueue != undefined) {
      this.$queue = this.observeDoc(refQueue)
    } else {
      this.$queue = undefined
    }
    // }
    return this.$queue
  }

  metaData() {
    return {
      queueId: this.state.queueId,
      orgId: this.state.orgId,
      token: this.state.token,
    }
  }

  async join(customField = '') {
    const documentReferenceOrg = this.orgDoc()
    const documentSnapshotOrg = await documentReferenceOrg.get()
    const dataOrg = documentSnapshotOrg.data()
    const code = dataOrg.code
    const defaultQueue = dataOrg.defaultQueue

    const documentReferenceQueue = await this.queueDoc(defaultQueue)
    if (documentReferenceQueue != undefined) {
    
      const documentSnapshotQueue = await documentReferenceQueue.get()
      const docId = documentSnapshotQueue.id
      const dataQueue = documentSnapshotQueue.data()

      const queueModel = new QueueModel(dataQueue.queue)

      const queue = queueModel.asArray()

      let newClientPosition

      if (queue.length == 0) {
        newClientPosition = 1
      } else {
        const lastCode = queue[queue.length - 1].code
        const currentPosition = Number(lastCode.substring(lastCode.length - 4))
        newClientPosition = currentPosition + 1
      }

      queueModel.enqueue({
          code: code + newClientPosition.toString().padStart(4, '0'),
          token: this.state.token,
          name: customField,
          timestamp: Math.round(Date.now() / 1000)
      })

      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `TOKEN ${this.state.token}`)
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `QUEUE DATA 1: ${JSON.stringify(queue)}`)

      await documentReferenceQueue.update({  queue, tokens: queueModel.tokens() })
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `QUEUED`)

      this.subscribeToQueue(LEAVE_PAGES.LEAVE, defaultQueue)
      await this.shared.updateStorageAndState(this, {
        [ IONIC_STORAGE_KEYS.QUEUE_ID ] : docId,
        [ IONIC_STORAGE_KEYS.IN_QUEUE ] : true
      })
    } else {
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `DEFAULT QUEUE ${defaultQueue} does not exist`)

      await this.shared.updateStorageAndState(this, {
        [ IONIC_STORAGE_KEYS.QUEUE_ID ] : undefined,
        [ IONIC_STORAGE_KEYS.IN_QUEUE ] : false
      })
    }
  }

  async getQueueMember() {
    const documentReferenceQueue = await this.queueDoc()
    if (documentReferenceQueue != undefined) {
      const documentSnapshotQueue = await documentReferenceQueue.get()
      const dataQueue = documentSnapshotQueue.data()
      const queueModel = new QueueModel(dataQueue.queue)
      return queueModel.peekByToken(this.state.token)
    }
    return undefined
  }

  async leave() {
    const documentReferenceQueue = await this.queueDoc()
    if (documentReferenceQueue != undefined) {
      const documentSnapshotQueue = await documentReferenceQueue.get()
      const dataQueue = documentSnapshotQueue.data()

      const queueModel = new QueueModel(dataQueue.queue)
      const queue = queueModel.asArray()

      if (queueModel.removeByToken(this.state.token)) {
        await documentReferenceQueue.set({ queue, tokens: queueModel.tokens() }, { merge: true })
      }
    }

    await this.shared.updateStorageAndState(this, {
      [ IONIC_STORAGE_KEYS.IN_QUEUE ] : false
    })
  }

  getState() {
    return this.state
  }

  addToState(key, value) {
    this.state[key] = value
  }

  addObjectToState(obj) {
    this.state = { ...this.state, ...obj }
  }

  removeKeysFromState(keys) {
    keys.forEach(key => delete this.state[key]);
  }

  async unsubscribe() {
    if (typeof this.subscription !== 'undefined' && !this.subscription.closed) {
      this.subscription.unsubscribe()
    }
  }

  async subscribeToQueue(leavePage, queueName = undefined) {

    await this.unsubscribe();
    const $liveData = await this.liveData(queueName)
    
    this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Live data ${$liveData}`)
    if ($liveData == undefined) {
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `No Live Data`)
      this.showLeftQueuePage(leavePage)
    } else {
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Live Data Exists`)
      this.subscription = $liveData
      .subscribe(async documentSnapshotQueue => {
        if (typeof documentSnapshotQueue === 'undefined') {
          this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Org ${this.state.orgId} or Queue ${this.state.queueId} not found in DB`)
          await this.ionicStorage.set('state', {})
          .then(() => {
            this.state = {}
            this.router.navigate([ROUTES.HOME], NAVIGATE_DEFAULT)
          })
          return false
        }

        const dataQueue = documentSnapshotQueue.data()
        this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Queue Name: ${dataQueue.name}`)
        
        const arrayPos = dataQueue.queue.findIndex(item => {
          return item.hasOwnProperty('token') && item.token === this.state.token
        })
       
        if (arrayPos !== -1) {
          await this.shared.updateStorageAndState(this, {
            [ IONIC_STORAGE_KEYS.QUEUE_ID ] : documentSnapshotQueue.id,
            [ IONIC_STORAGE_KEYS.IN_QUEUE ] : true,
          })

          if (dataQueue.hasOwnProperty('type') && dataQueue.type == 'private') {
            this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `NAVIGATING in position ONE in queue called ${dataQueue.name}`)
            this.router.navigate([ROUTES.POSITION_ONE], NAVIGATE_DEFAULT)
          } else {
            this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `NAVIGATING in a public queue called ${dataQueue.name}`)
            
            const position = arrayPos + 1
            this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Position: ${position}`)

            switch (position) {
              case 1:
                this.router.navigate([ROUTES.POSITION_TWO], NAVIGATE_DEFAULT)
                break
              case 2:
                this.router.navigate([ROUTES.POSITION_THREE], NAVIGATE_DEFAULT)
                break
              default:
                this.router.navigate([ROUTES.STATUS], NAVIGATE_DEFAULT)
            }
          }
        } else {
          // If not in the original queue,
          // See if client has been dequeued to next queue
          // If no next queue, who leave page

          this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Not found in : ${dataQueue.name}`)

          if (dataQueue.dequeue != '') {
            this.subscribeToQueue(leavePage, dataQueue.dequeue)
          } else {
            this.showLeftQueuePage(leavePage)
          }
        }
      })
    }
  }

  async showLeftQueuePage(leavePage) {
    await this.unsubscribe()

    await this.shared.updateStorageAndState(this, {
      [ IONIC_STORAGE_KEYS.IN_QUEUE ] : false
    })

    this.router.navigate([`/${leavePage}`], NAVIGATE_DEFAULT)
    this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Client no longer in queue`)
    this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Navigating to ${leavePage}`)
  }

  async showQueuePage(orgId) {
    if (await this.isApproved(orgId)) {
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Queue approved`)
      const isFrozen = await this.isFrozen(orgId)
      if (!isFrozen) {
        await this.ionicStorage.ready()
        
        await this.shared.updateStorageAndState(this, {
          [ IONIC_STORAGE_KEYS.ORG_ID ] : orgId
        })

        this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Added ${orgId} to state`)
        this.router.navigate([ ROUTES.QUEUE ], NAVIGATE_DEFAULT)
      } else {
        this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Queue is frozen`)
        this.router.navigate([ ROUTES.UNAVAILABLE ], NAVIGATE_DEFAULT)
      }
    } else {
      this.shared.log(LOG_CATEGORIES.QUEUE_MANAGER, `Queue not approved`)
      this.router.navigate([ ROUTES.UNAVAILABLE ], NAVIGATE_DEFAULT)
    }
  }
}
