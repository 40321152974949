import { NgModule, ErrorHandler } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'

import { AppComponent } from './app.component'
import { ErrorService } from '../error.service';
import { FcmService } from '../fcm.service';
import { ConnectivityService } from '../connectivity.service';
import { AppRoutingModule } from './app-routing.module'
import { SharedService } from '../shared.service'
import { IonicStorageModule } from '@ionic/storage'
import { AngularFireModule } from 'angularfire2'
import { AngularFirestoreModule } from 'angularfire2/firestore'
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx'
import { FirestoreSettingsToken } from 'angularfire2/firestore'
import { AngularFireStorageModule } from 'angularfire2/storage'
import { firestoreConfig } from '../db'
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx'
import { LocalNotifications } from '@ionic-native/local-notifications/ngx'
import { Device } from '@ionic-native/device/ngx'
import { QRScanner } from '@ionic-native/qr-scanner/ngx'
import { NativeAudio } from '@ionic-native/native-audio/ngx'
import { Network } from '@ionic-native/network/ngx'

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ animated: false } ),
    AppRoutingModule,
    IonicStorageModule.forRoot({
      driverOrder: ['indexeddb', 'websql', 'sqlite']
    }),
    AngularFireModule.initializeApp(firestoreConfig),
    AngularFirestoreModule,
    AngularFireStorageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BarcodeScanner,
    AngularFireStorageModule,
    FCM,
    FcmService,
    Network,
    ConnectivityService,
    LocalNotifications,
    Device,
    QRScanner,
    NativeAudio,
    { provide: ErrorHandler, useClass: ErrorService }

    /*{
      provide: FirestoreSettingsToken,
      useValue: {
        host: 'localhost:8080',
        ssl: false
      }
    }*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
