import { Injectable } from '@angular/core'
import { Network } from '@ionic-native/network/ngx'
import { CONNECTION_STATES, LOG_CATEGORIES, ROUTES } from './constants'
import { SharedService } from './shared.service'
import { QueueManagerService } from './queue-manager.service'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {

  constructor(private network: Network, private shared: SharedService, private router: Router, private queueManager: QueueManagerService) {}

  private disconnectSubscription
  private connectSubscription
  private changeSubscription
  private status = CONNECTION_STATES.CONNECTED

  init() {

    this.changeSubscription = this.network.onChange().subscribe(() => {
      this.shared.log(LOG_CATEGORIES.CONNECTIVITY, 'network connection status changed')
    })

    /* The NetworkManager plugin seems initialized before getting to this point, 
    so the disconnect subscription will trigger as soon as it is defined. */
    this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      this.shared.log(LOG_CATEGORIES.CONNECTIVITY, 'network was disconnected')
      this.status = CONNECTION_STATES.DISCONNECTED
      this.shared.navigateToConnectivity()
    })
    this.shared.log(LOG_CATEGORIES.CONNECTIVITY, 'Disconnect subscription')

    this.connectSubscription = this.network.onConnect().subscribe(() => {
      this.shared.log(LOG_CATEGORIES.CONNECTIVITY, 'network was connected')
      this.status = CONNECTION_STATES.CONNECTED
      // We just got a connection but we need to wait briefly
       // before we determine the connection type. Might need to wait.
      // prior to doing any api requests as well.

      this.shared.initializeApp(this.queueManager)
      
      setTimeout(() => {
        if (this.network.type === 'wifi') {
          this.shared.log(LOG_CATEGORIES.CONNECTIVITY, 'Wifi Connection')
        }
      }, 3000);
    });
  }

  stop() {
    this.connectSubscription.unsubscribe()
    this.disconnectSubscription.unsubscribe()
  }

  get connectionStatus() {
    return this.status;
  }
}
