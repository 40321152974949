import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'status',
    loadChildren: () => import('./status/status.module').then( m => m.StatusPageModule)
  },
  {
    path: 'queue',
    loadChildren: () => import('./queue/queue.module').then( m => m.QueuePageModule)
  },
  {
    path: 'scan',
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'position-three',
    loadChildren: () => import('./position-three/position-three.module').then( m => m.PositionThreePageModule)
  },
  {
    path: 'position-two',
    loadChildren: () => import('./position-two/position-two.module').then( m => m.PositionTwoPageModule)
  },
  {
    path: 'position-one',
    loadChildren: () => import('./position-one/position-one.module').then( m => m.PositionOnePageModule)
  },
  {
    path: 'leave',
    loadChildren: () => import('./leave/leave.module').then( m => m.LeavePageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'unavailable',
    loadChildren: () => import('./unavailable/unavailable.module').then( m => m.UnavailablePageModule)
  },
  {
    path: 'connectivity',
    loadChildren: () => import('./connectivity/connectivity.module').then( m => m.ConnectivityPageModule)
  },
  {
    path: 'server-unavailable',
    loadChildren: () => import('./server-unavailable/server-unavailable.module').then( m => m.ServerUnavailablePageModule)
  },
  {
    path: 'private-queue',
    loadChildren: () => import('./private-queue/private-queue.module').then( m => m.PrivateQueuePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
